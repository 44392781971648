// import routes from "@/routes/routes.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import hasQueryString from "@/util/has-query-string.mjs";
// import { initABFeature } from "@/util/use-ab-feature.mjs";

// const index = routes.findIndex((r) => r.key === "lol-postmatch");
// const route = routes[index];

export async function setup() {
  const enabled = hasQueryString("__BLITZ_COACHING__");
  // const enabled =
  //   (await initABFeature(["coaching", "lol-postmatch-coaching"], 10)) ||
  //   IS_DEV ||
  //   hasQueryString("__BLITZ_COACHING__");
  if (!enabled) return;

  const component = await import("feature-lol-coaching/PostMatchCoaching.jsx");
  lolRefs.PostMatchCoachingSection = component.default;
}

export function teardown() {
  lolRefs.PostMatchCoachingSection = null;
}
